<template>
  <section class="section">
    <div class="section-header">
      <h1>Dashboard</h1>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-6 col-sm-6 col-12">
        <div class="card card-statistic-1">
          <div class="card-icon bg-primary">
            <i class="fas fa-store"></i>
          </div>
          <div class="card-wrap">
            <div class="card-header">
              <h4>Total Product Sale</h4>
            </div>
            <div class="card-body">{{ dashboard?.product_sale_total }}</div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6 col-12">
        <div class="card card-statistic-1">
          <div class="card-icon bg-danger">
            <i class="fas fa-wine-bottle"></i>
          </div>
          <div class="card-wrap">
            <div class="card-header">
              <h4>Total Product Ingredient</h4>
            </div>
            <div class="card-body">
              {{ dashboard?.product_ingredient_total }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6 col-12">
        <div class="card card-statistic-1">
          <div class="card-icon bg-warning">
            <i class="fas fa-store"></i>
          </div>
          <div class="card-wrap">
            <div class="card-header">
              <h4>Total Order</h4>
            </div>
            <div class="card-body">{{ dashboard?.order_total }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <h4>Top Items</h4>
          </div>
          <div class="card-body p-0">
            <div class="table-responsive">
              <table class="table table-striped mb-0">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Sold</th>
                    <th>Image</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(top, i) in dashboard?.top_items" :key="i">
                    <td>{{ top.name }}</td>
                    <td>{{ top.sold }}</td>
                    <td>
                      <img
                        v-if="top.image"
                        v-img
                        :alt="top.name"
                        height="100px"
                        :src="top.image"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <h4>Latest Order</h4>
          </div>
          <div class="card-body p-0">
            <div class="table-responsive">
              <table class="table table-striped mb-0">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Payment Type</th>
                    <th>Table Number</th>
                    <th>Venue Name</th>
                    <th>Status</th>
                    <th>Gross Sale</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(top, i) in dashboard?.latest_orders" :key="i">
                    <td>{{ top.customer_name }}</td>
                    <td>{{ top.name_payment_type }}</td>
                    <td>{{ top.place_name }}</td>
                    <td>{{ top.name_venue }}</td>
                    <td>{{ top.status_payment }}</td>
                    <td>Rp {{ formatPrice(top.total_price) }}</td>
                    <td>
                      <router-link
                        :to="{
                          name: 'CashierEdit',
                          params: { id: top.payment_order_id },
                        }"
                        class="badge badge-success mr-1"
                        >Show</router-link
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { apiGet } from '../../services/api';

export default {
  data() {
    return {
      dashboard: {},
      dateRange: '',
    };
  },
  methods: {
    getData() {
      apiGet('dashboard').then((result) => {
        this.dashboard = result.data.data;
      });
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace('.', ',');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
